.eventRegistrationPage {
  padding: 8rem 0 2rem 0;
}
.eventRegistrationPage .container {
  display: flex;
  justify-content: center;
}

.eventTitle {
  font-size: 2.5rem;
  color: var(--mauve);
  margin-bottom: 2rem;
  text-align: center;
}

.registrationForm {
  background-color: #f8f8f8;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: var(--dark-blue);
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.formGroup textarea {
  height: 100px;
  resize: vertical;
}

.errorMessage {
  color: var(--red);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

@media (max-width: 768px) {
  .pageHeaderSection h1 {
    font-size: 2rem;
  }

  .registrationForm {
    padding: 1.5rem;
  }

  .eventTitle {
    font-size: 2rem;
  }
}
