.testimonyWrapper {
  /* border: solid red 2px; */
  width: 40rem;
}
/* .testi-right .header {
  margin-bottom: 0rem;
} */
.testi-right h1 {
  font-size: 3.5rem;
}
.survival-headerColor {
  color: var(--grey-deep);
}

.donate-headerColor {
  color: var(--mauve);
}

.testimonyWrapper .bottom {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 40rem;
  /* border: solid white 2px; */
}

.testimonyWrapper .quote-sign img {
  width: 5rem;
}
.testi-right {
  width: 85%;
  /* border: solid blue 2px; */
}
.testimonySwiper {
  /* width: 100%; */
  padding-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: solid blue 2px; */
  padding-right: 4rem;
  margin: 0 !important;
}

.testimonial {
  text-align: center;
  padding: 0;
  user-select: none;
  /* border: solid black 2px; */
}

.swiper-wrapper {
  margin: 0s;
}

.swiper-slide p {
  margin: 1rem 0;
  width: 80%;
  text-align: left;
  /* border: solid red 2px; */
}

.survival-text {
  color: var(--color-white);
}

.donate-text {
  color: var(--mauve);
}

.testifier {
  text-align: left;
  /* border: solid red 2px; */
}

.survival-name {
  color: var(--grey-deep);
}

.donate-name {
  color: var(--mauve);
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  color: var(--red);
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  /* border: solid red 2px; */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.testimonySwiper {
  position: unset;
  width: 100%;
}

.swiper-button-prev {
  background-size: contain;
  /* border: solid red 2px; */
}

.survival-prev-button {
  background-image: url(../../assets/images/arrow-left.png);
}
.donate-prev-button {
  background-image: url(../../assets/images/arrow-left-mauve.png);
}

.swiper-button-next {
  background-size: contain;
  /* border: solid red 2px; */
}

.survival-next-button {
  background-image: url(../../assets/images/arrow-right.png);
}
.donate-next-button {
  background-image: url(../../assets/images/arrow-right-mauve.png);
}

.testimonySwiper div.swiper-button-prev {
  left: 85%;
}

.testimonySwiper div.swiper-button-next {
  right: 1rem;
  /* margin-right: 1.5rem; */
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--yellow);
}

@media screen and (max-width: 900px) {
  .testimony {
    width: 100%;
  }

  .testimonySwiper {
    width: 100%;
    padding-bottom: 4rem;
    padding-right: 0rem;
    padding-left: 1rem;
  }
  .testimonySwiper div.swiper-button-prev {
    left: 0;
  }

  .testimonySwiper div.swiper-button-next {
    right: 0;
  }
}

@media (max-width: 768px) {
  .testimonyWrapper {
    /* border: solid red 2px; */
    width: 100%;
  }

  .testimonyWrapper .bottom {
    flex-direction: column;
    width: 100%;
    /* border: solid white 2px; */
  }

  .testi-right {
    width: 100%;
    /* border: solid blue 2px; */
  }

  .swiper-slide {
    padding-left: 1.5rem;
  }

  .testi-right .header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
