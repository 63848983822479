.imageWire {
  position: absolute;
  /* border: solid blue 2px; */
  width: 100%;
  height: 70vh;
  z-index: 2;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.showcaseWire {
  background-position: 75% 10px;
}

.theSilentEpidemicWire {
  background-position: 5% 10px;
  background-size: contain;
  height: 50vh;
  /* border: solid blue 2px; */
}

.affectsYouWire {
  background-position: 100% 0px;

  height: 60vh;
}

.pageHeaderWire {
  background-position: 100% 0px;
  height: 70vh;
}

.pageHeaderWire2 {
  background-position: 100% -10px;
  height: 80vh;
}

.pageHeaderWire3 {
  background-position: 100% 10px;
  height: 50vh;
  background-size: contain;
}

.pageHeaderWire4 {
  background-position: 100% 10px;
  height: 60vh;
  background-size: contain;
}

.homeAboutSectionWire {
  background-position: 65% -10px;
  background-size: cover;
  height: 50vh;
  /* border: solid blue 2px; */
}

.survivorStoriesWire {
  background-position: 5% 10px;
  background-size: contain;
  height: 52vh;
  /* border: solid blue 2px; */
}

@media (max-width: 768px) {
  .imageWire {
    height: 40vh;
  }

  .showcaseWire {
    background-position: 78% 10px;
  }

  .theSilentEpidemicWire {
    background-position: 15% 40px;
    background-size: cover;
  }

  .homeAboutSectionWire {
    background-position: 45% 40px;
    background-size: cover;
  }

  .survivorStoriesWire {
    /* background-position: 15% 40px;
    background-size: cover; */

    display: none;
  }
  .survivorStoriesWireDonate {
    /* background-position: 78% 10px;
    height: 70vh;
    background-size: cover; */
    display: none;
  }

  .pageHeaderWire3 {
    background-position: 78% 10px;
    height: 70vh;
    background-size: cover;
  }

  .pageHeaderWire4 {
    background-position: 78% 10px;
    height: 70vh;
    background-size: cover;
  }
}
