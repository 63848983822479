.contactSection {
  padding: 2rem 0;
}

.itemWrapper {
  display: flex;
  gap: 2rem;
}
.itemLeft {
  flex: 1;
}
.itemRight {
  flex: 2;
}

.itemLeft .header {
  margin-bottom: 2rem;
}

.itemLeft .header h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.itemInfo {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* border: solid red 2px; */
}

.itemInfo .hor {
  /* border: solid red 2px; */
  justify-content: flex-start;
}

.itemInfo .ver {
  /* border: solid red 2px; */
  justify-content: flex-start;
  width: 100%;
}

.iconItem {
  height: 5rem;
  width: 5rem;
  background-color: var(--brown);
  border-radius: 2rem;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconItem .icon {
  width: 2rem;
}

.flexMore {
  flex: 3;
}

.itemRight .map iframe {
  border-radius: 2rem;
}

.contactFormSection {
  padding: 2rem 0;
}

.contactFormSection .container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
}
@media (max-width: 768px) {
  .itemWrapper {
    flex-direction: column;
  }

  .contactFormSection .container {
    padding: 0;
  }
}
