.volunteer-cards-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.volunteer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #f8f8f8; */
  border: solid 2px var(--brown);
  border-radius: 2rem;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.volunteer-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.volunteer-card h3 {
  color: var(--red);
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: center;
}

.volunteer-card p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: center;
}

.volunteer-card [class^="volunteer-card-"] {
  font-style: italic;
  color: #888;
}

/* Tablet view */
@media (min-width: 768px) {
  .volunteer-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Desktop view */
@media (min-width: 1024px) {
  .volunteer-cards-container {
    grid-template-columns: repeat(2, 1fr);
    max-width: 900px;
  }
}

/* Large Desktop view */
@media (min-width: 1200px) {
  .volunteer-cards-container {
    gap: 30px;
  }
}
