.volunteerTestimonies .testimonials__container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: solid black 2px; */
}
.vt-card {
  background-color: var(--red);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 10px;
  width: 22rem;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .vt-card {
    width: 100%;
  }
}
.avatarWrapper {
  flex: 1;
}
.avatar {
  width: 5rem;
  height: 5rem;
  border: solid 2px var(--teal);
  border-radius: 50%;
  display: flex;
  overflow: hidden;
}

.personInfoImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--color-white);
}
.details .name {
  margin-bottom: 1.5rem;
}
.details article {
  text-align: left;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--red);
}
