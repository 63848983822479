.paymentIcons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin: 0rem 0;
  width: 100%;
  /* border: solid red 2px; */
}

.paymentIcons .p-icon {
  font-size: 3rem;
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  color: var(--red);
}

.paymentIcons .p-icon:last-child {
  width: 3rem;
}

.paymentIcons .p-icon:hover {
  color: var(--yellow);
}

.p-btnHolder {
  margin: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.p-btnHolder .btnPrimary {
  background-color: var(--red);
  width: 50%;
}

@media screen and (max-width: 600px) {
  .p-btnHolder .btnPrimary {
    width: 100%;
  }
}
