.showcase {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--mauve);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* border: solid red 2px; */
}

.showcase .container {
  /* border: solid red 2px; */
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.showcase h1 {
  font-size: 3.5rem;
  color: var(--yellow);
  font-weight: 800;
}

.showcase article {
  color: var(--color-white);
  font-size: x-large;
}

.showcase .btnPrimary {
  color: var(--mauve);
}

.showcase .imageFadeWrapper {
  height: 50vh;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 2;
  position: relative;
  overflow: hidden;
  /* border: solid blue 2px; */
}

@media (min-width: 769px) {
  .showcase .imageFadeWrapper {
    height: 100vh; /* Larger height for desktop */
  }
}

.showcase .fadeImage {
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border: solid blue 2px; */
}

.stackTop {
  position: relative;
  z-index: 3;
}

.theSilentEpidemicSection {
  background-color: var(--yellow);
}

.theSilentEpidemicSection h1 {
  font-size: 3rem;
  color: var(--mauve);
  font-weight: 800;
}

.affectsYouSection {
  background-color: var(--red);
}

.affectsYouSection h1 {
  font-size: 3rem;
  color: var(--yellow);
  font-weight: 800;
}

.affectsYouSection article {
  color: var(--color-white);
}

.homeAboutSection {
  background-color: var(--color-white);
}

.homeAboutSection h1 {
  font-size: 3rem;
  color: var(--mauve);
  font-weight: 800;
}

.homeServiceSection {
  background-color: var(--grey-deep);
}

.homeServiceSection h1 {
  font-size: 3rem;
  font-weight: 800;
}

.homeServiceSection .fadeImage {
  object-fit: contain;
  /* border: solid blue 2px; */
}

.survivorStoriesSection {
  background-color: var(--brown);
}

@media (max-width: 768px) {
  .showcase {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .showcase .container {
    /* border: solid red 2px; */
    height: 100vh;
  }

  .showcase .hor {
    margin-top: 6rem;
    flex-direction: column;
    /* border: solid blue 2px; */
    height: 100%;
  }

  .theSilentEpidemicSection .hor {
    flex-direction: column-reverse;
  }

  .homeAboutSection .hor {
    flex-direction: column-reverse;
  }
  .survivorStoriesSection {
    height: 100vh;
  }
  .survivorStoriesSection .hor {
    flex-direction: column-reverse;
  }

  .showcase h1 {
    font-size: 2.5rem;
  }

  .showcase .fadeImage {
    position: absolute;
    bottom: 0;
    left: 0;

    object-fit: cover;
  }

  .showcase .ver {
    width: 100%;
  }
}
