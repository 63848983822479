.helpMattersSection {
  padding: 2rem 0;
}
.imgContentWrapper {
  width: 70%;
  position: relative;
  /* width: 25rem; */
  border-radius: 3rem;
  overflow: hidden;
}

.secImage {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease !important;
}

.imgContentWrapper:hover .secImage {
  transform: scale(1.1);
}

.volunteerVoices,
.waysToVolunteer {
  padding: 2rem 0;
}
.volunteerVoices .txtContent,
.waysToVolunteer .txtContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.volunteerVoices .txt,
.waysToVolunteer .txt {
  width: 60%;
  margin-bottom: 2rem;
}
.volunteerVoices .bottom,
.waysToVolunteer .bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.volunteerVoices .voices {
  position: absolute;
  z-index: 2;
  width: 82%;
  /* border: solid blue 2px; */
}
.volunBackdrop {
  background-color: var(--grey-deep);
  height: 20rem;
  width: 70%;
  border-radius: 3rem;
}

.getInTouchSection {
  padding: 2rem 0;
}

.getInTouchSection .container {
  border: solid 1px var(--brown);
  padding: 2rem;
  border-radius: 3rem;
  width: 70%;
}

.topLeft {
  height: 25rem;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: solid blue 2px; */
}

@media (max-width: 768px) {
  .imgContentWrapper {
    width: 100%;
  }
  .volunteerVoices .txt,
  .waysToVolunteer .txt {
    width: 100%;
  }
  .getInTouchSection .container {
    width: 82%;
    padding: 1rem;
    border-radius: 2rem;
  }
  .volunBackdrop {
    height: 18rem;
    width: 60%;
    border-radius: 2rem;
  }
}
