.donateFaded {
  opacity: 0.8;
  mix-blend-mode: soft-light;
}
.yourImpactSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 2rem;
  /* border: solid red 2px; */
}

.yourImpactSection .container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* border: solid red 2px; */
}
.yourImpactSection .txtHeader,
.yourImpactSection article {
  text-align: center;
  /* border: solid red 2px; */
}
.yourImpactSection .txtContent {
  justify-content: center;
  align-items: center;
  width: 100%;
  /* border: solid red 2px; */
}
.yourImpactSection .txtHeader h1 {
  font-size: 4rem;
}
.yourImpactSection .txtHeader h4 {
  font-size: 2rem;
}
.yourImpactSection .txt {
  width: 70%;
  /* border: solid red 2px; */
}

.chooseYourImpact {
  padding-top: 4rem;
  width: 100%;
  /* border: solid red 2px; */
}

.chooseYourImpact .header h2 {
  font-size: 2.5rem;
}

/* .chooseYourImpact .paymentWrapper {
  border: solid black 2px;
} */

.survivorStoriesSectionDonate {
  min-height: 80vh;
  /* border: solid red 2px; */
}

.whereYourDonationGoes {
  padding-bottom: 2rem;
}
.whereYourDonationGoes .txtContent {
  justify-content: center;
  align-items: center;
}

.chart {
  width: 70%;
  margin: 1rem 0;
}
.whereYourDonationGoes .txtHeader h1 {
  font-size: 4rem;
}
.whereYourDonationGoes article {
  text-align: center;
  /* border: solid red 2px; */
}

.paymentWrapper {
  /* border: solid red 2px; */

  /* background-color: var(--yellow); */
  padding: 2rem;
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paymentWrapper .holder {
  width: 80%;
}

@media (max-width: 768px) {
  .donateFaded {
    opacity: 0.8;
    mix-blend-mode: soft-light;
  }

  .yourImpactSection .txtHeader,
  .yourImpactSection article {
    text-align: left;
  }
  .yourImpactSection .txtContent {
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border: solid red 2px; */
  }
  .yourImpactSection .txtHeader h1 {
    font-size: 3rem;
  }

  .yourImpactSection .txt {
    width: 100%;
  }

  .chooseYourImpact {
    padding-top: 1rem;
  }

  .whereYourDonationGoes {
    padding-bottom: 2rem;
  }
  .whereYourDonationGoes .txtContent {
    justify-content: center;
    align-items: center;
  }

  .chart {
    width: 100%;
  }
  .whereYourDonationGoes .txtHeader h1 {
    font-size: 4rem;
  }
  .whereYourDonationGoes article {
    text-align: left;
  }

  .paymentWrapper {
    padding: 0;
  }

  .paymentWrapper .holder {
    width: 100%;
  }
}
