.eventDetailPage {
  padding: 2rem 0;
}

.pageHeaderSection {
  padding: 4rem 0;
  position: relative;
}

.eventDetailContent {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.eventInfo {
  margin-bottom: 2rem;
}

.eventNotice {
  font-style: italic;
  color: var(--red);
  margin-bottom: 1rem;
}

.eventDate,
.eventTime,
.eventLocation {
  margin-bottom: 0.5rem;
}

.eventOverview,
.eventContent {
  margin-bottom: 2rem;
}

.eventOverview h2,
.eventContent h2 {
  color: var(--mauve);
  margin-bottom: 1rem;
}

.eventActions {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .eventActions {
    flex-direction: column;
  }
}
