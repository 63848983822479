.pageHeaderSection {
  padding-top: 6rem;
}

.pageHeaderSection h1 {
  font-size: 3rem;
  font-weight: 800;
}

.faded {
  opacity: 0.9;
  mix-blend-mode: multiply;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: solid blue 2px; */
}
.imgWrapper {
  display: flex;
  position: relative;
  width: 80%;
  /* border: solid red 2px; */
}
.backDrop {
  background-color: var(--grey-deep);
  height: 22rem;
  width: 30rem;
  border-radius: 2rem;
  top: 0;
  left: 0;
}
.topImg {
  position: absolute;
  height: 22rem;
  width: 30rem;
  border-radius: 2rem;
  z-index: 2;
  left: 2rem;
  top: 2rem;
  overflow: hidden;
}
.topImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagesWrapper {
  display: flex;
  position: relative;
  width: 80%;
  /* height: 20rem; */
  /* border: solid red 2px; */
}

.backImg {
  top: 0;
  left: 0;
  height: 20rem;
  width: 15rem;
  border-radius: 2rem;
  overflow: hidden;
  /* border: solid red 2px; */
}

.overlapImg {
  position: absolute;
  height: 20rem;
  width: 15rem;
  border-radius: 2rem;
  z-index: 2;
  left: 12rem;
  top: 7rem;
  overflow: hidden;
  /* border: solid red 2px; */
}

.tileImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .center {
    align-items: center;
    justify-content: flex-start;
  }
  .up {
    justify-content: flex-start !important;
    align-items: flex-start !important;

    /* border: solid blue 2px; */
  }
  .backDrop {
    background-color: var(--grey-deep);
    height: 12rem;
    width: 95%;
    border-radius: 2rem;
    top: 0;
    left: 0;
  }
  .topImg {
    position: absolute;
    height: 12rem;
    width: 100%;
    border-radius: 2rem;
    z-index: 2;
    left: 2rem;
    top: 2rem;
    overflow: hidden;
  }

  .backImg {
    top: 0;
    left: 0;
    height: 15rem;
    /* width: 10rem; */
    width: 80%;
    border-radius: 2rem;
    overflow: hidden;
    /* border: solid red 2px; */
  }

  .overlapImg {
    position: absolute;
    height: 15rem;
    width: 80%;
    border-radius: 2rem;
    z-index: 2;
    left: 8rem;
    top: 7rem;
    overflow: hidden;
    /* border: solid red 2px; */
  }
  .moreHeightSection {
    height: 130vh !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
