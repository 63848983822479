.eventsFaded {
  mix-blend-mode: hard-light;
}
.upComingEvents {
  padding: 2rem 0;
}
.upComingEvents .txtHeader h1,
.pastEvents .txtHeader h1 {
  color: var(--red);
  text-transform: capitalize;
  text-align: center;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}
.comingEventsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.comingEventsCards {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 80%;
}
.comingEventsCard {
  display: flex;
  gap: 2rem;
  box-sizing: border-box;
}

.comingEventsCard:nth-child(even) {
  flex-direction: row-reverse;
}

.comingEventsCard .left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.comingEventsCard .left .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.comingEventsCard .left h2 {
  color: var(--mauve);
  text-transform: capitalize;
  font-size: 2rem;
  text-align: center;
}

.comingEventsCard .left .subTitle {
  text-align: center;
  font-weight: 600;
  /* border: solid red 2px; */
}
/* .comingEventsCardImg {
  border: solid red 2px;
} */
.eventNotice {
  width: 80%;
}
.eventNotice article {
  text-align: center;
}
.imageHolder {
  position: relative;
  width: 25rem;
  border-radius: 3rem;
  overflow: hidden;
  /* border: solid red 2px; */
}
.eventItemImage {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease !important;
}
.imageHolder:hover .eventItemImage {
  transform: scale(1.1);
}
.comingEventsCard .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.eventOverview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pastEvents {
  padding: 2rem 0;
}

.pastEventsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pastEventsCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  width: 80%;
  /* border: solid red 2px; */
}

.pastEventsCard {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* border: solid blue 2px; */
}

.pastEventsCard .top {
  height: 200px;
  overflow: hidden;
  /* border: solid red 2px; */
}

.pastEventsCard .bottom {
  padding: 1rem;
  background-color: #f8f8f8;
  /* border: solid red 2px; */
}

.pastEventsCard .header h2 {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
  color: var(--mauve);
  text-transform: capitalize;
}

.pastEventsCard .subTitle {
  font-size: 0.9rem;
  color: #666;
  text-transform: capitalize;
}

/* Tablet styles */
@media screen and (min-width: 768px) {
  .txtHeader h1 {
    font-size: 3rem;
  }

  .comingEventsCard {
    flex-direction: row;
    gap: 2rem;
  }

  .comingEventsCard .left,
  .comingEventsCard .right {
    width: 50%;
  }

  .pastEventsCards {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .upComingEvents .txtHeader h1,
  .pastEvents .txtHeader h1 {
    font-size: 2.5rem;
  }

  .txtHeader h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .comingEventsCards,
  .pastEventsCards {
    width: 100%;
  }
  .comingEventsCard:nth-child(even) {
    flex-direction: column;
  }
  .comingEventsCard {
    flex-direction: column;
    gap: 1rem;
  }

  .comingEventsCard .left,
  .comingEventsCard .right {
    width: 100%;
  }

  .imageHolder {
    width: 100%;
    max-width: 25rem;
  }

  .pastEventsCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .pastEventsCard .bottom {
    padding: 1rem;
    background-color: rgba(248, 248, 248, 0.8);
  }
}

/* Skeleton loader styles */
.skeleton-card {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  background: #f0f0f0;
  animation: pulse 1.5s infinite;
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background: #e0e0e0;
  margin-bottom: 1rem;
}

.skeleton-text {
  height: 1rem;
  background: #e0e0e0;
  margin-bottom: 0.5rem;
}

.skeleton-text:last-child {
  width: 80%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* Animations for event cards */
.event-card-animation {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
