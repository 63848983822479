@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: Nexa Bold;
  src: url(./ui/fonts/Nexa.otf);
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --mauve: #9605c6;
  --brown: #560309;
  --red: #cc373e;
  --yellow: #f9cc10;
  --grey-deep: #848484;
  --color-white: #fff;
  --color-light: #fafbff;
  --light-bkg: #f5f0f0;
  --transition: all 400ms ease;
  --light-color: rgba(#fff, 0.7);
  --black-color: #0c031b;
  --dark-color: rgba(var(--black-color), 0.7);
  --container-lg: 82%;
  --container-md: 90%;
  --transition: all 400ms ease;
}

#root {
  white-space: pre-line;
  min-height: 100%;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  overflow-y: auto;
}

body {
  font-family: sans-serif, "poppins";
  line-height: 1.5;
  color: var(--dark-color);
  background-color: var(--color-white);
  /* user-select: none; */
  overflow-x: hidden;
  position: relative;
  overscroll-behavior-y: none;
  height: 100%;
  overflow-y: auto;
}

.container {
  width: var(--container-lg);
  margin: 0 auto;
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  font-family: Nexa Bold;
}

section p {
  width: 100%;
  margin: 0.6rem auto 2.5rem;
}

.pt-6 {
  padding-top: 6rem;
}

.btn-white {
  min-width: 8rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.55rem 1rem;
  border-radius: 1rem;
  background: #fff;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-white:hover {
  background: lighten(#fff, 8%);
  color: #000;
  box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
  transform: scale(0.94);
}

.btnPrimary {
  min-width: 8rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.55rem 1rem;
  border-radius: 10px;
  background: var(--yellow);
  color: var(--color-white);
  font-weight: 500;
  cursor: pointer;
  transition: all 400ms ease;
}

.btnPrimary:hover {
  background: lighten(#f1ae31, 8%);
  color: var(--color-white);
  box-shadow: 0 1rem 1.6rem rgba(#f1ae31, 0.15);
  transform: scale(0.94);
}

.hor {
  display: flex;
  gap: 20px;
}

.ver {
  flex: 1;
}

.ver,
.hor {
  /* border: solid red 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.donateNavBtn,
.donateNavBtn:focus {
  background: var(--red);
  padding: 6px 20px;
  border-radius: 1rem;
  color: var(--color-white);
  transition: all 400ms ease;
}

.donateNavBtn:hover,
.donateNavBtn:focus:hover {
  color: var(--color-white);
  background: var(--color-light);
  transform: scale(0.94);
}

.video__overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(0, 165, 75, 0.1);
  background-image: linear-gradient(transparent, #008080);
  z-index: 2;
}

.hero-bgVid {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.imageWrapper {
  width: 90%;
  height: 90%;
  display: flex;
  /* border: solid red 2px; */
}

.progressiveImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.pageSection {
  min-height: 80vh;
  /* padding: 2rem 0; */

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageSection .container {
  /* border: solid red 2px; */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pageSection .imageFadeWrapper {
  height: 50vh;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1.5;
  position: relative;
  overflow: hidden;

  /* border: solid blue 2px; */
}

@media (min-width: 769px) {
  .pageSection .imageFadeWrapper {
    height: 80vh; /* Larger height for desktop */
  }
}

.pageSection .fadeImage {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border: solid blue 2px; */
}

@media (max-width: 768px) {
  .pageSection .container {
    /* border: solid red 2px; */
    height: 100vh;
  }

  .pageSection .hor {
    margin-top: 1rem;
    flex-direction: column;
    /* border: solid blue 2px; */
    height: 100%;
  }

  .pageSection h1 {
    font-size: 2rem !important;
  }

  .pageSection .fadeImage {
    position: absolute;
    bottom: 0;
    left: 0;

    object-fit: cover;
  }

  .pageSection .ver {
    width: 100%;
  }
}

.txtContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.txtContent h2 {
  text-transform: capitalize;
  font-size: 3rem;
  font-weight: 800;
}

.txtContent h4 {
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 600;
}

.bkgRed {
  background-color: var(--red);
}

.bkgYellow {
  background-color: var(--yellow);
}

.bkgMauve {
  background-color: var(--mauve);
}

.bkgGrey {
  background-color: var(--grey-deep);
}

.bkgBrown {
  background-color: var(--brown);
}

.bkgLight {
  background-color: #f5f0f0;
}

.colorRed {
  color: var(--red);
}

.colorBrown {
  color: var(--brown);
}

.colorYellow {
  color: var(--yellow);
}

.colorMauve {
  color: var(--mauve);
}

.colorWhite {
  color: var(--color-white);
}

.colorGrey {
  color: var(--grey-deep);
}

.pb2 {
  padding-bottom: 2rem;
}

@media screen and (max-width: 600px) {
  .hor {
    flex-direction: column;
  }

  .txtContent h2 {
    font-size: 2rem;
  }
  .columnReversed {
    flex-direction: column-reverse !important;
  }
  /* .pageSection {
    height: auto !important;
  } */
}
