.formContainer {
  width: 80%;
  background-color: var(--brown);
  padding: 2rem;
  border-radius: 2rem;
}
.formCol {
  display: flex;
  justify-content: space-between;
  gap: 2%;
}
@media screen and (max-width: 600px) {
  .formCol {
    flex-direction: column;
  }
  .formContainer {
    width: 100%;
    padding: 1.2rem;
  }
}
.form-field {
  margin-bottom: 2rem;
  width: 100%;
}

.form-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.form-field span {
  display: block;
  font-weight: lighter;
  font-size: 14px;
  margin-bottom: 0.2rem;
}

.form-field input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  background-color: #f0eff2;
  border-radius: 2rem;
}

.form-field input[type="checkbox"] {
  width: 20px;
  padding: 0;
  font-size: 1rem;
  margin-left: 0;
}

.form-field textarea {
  width: 20px;
  padding: 0;
  font-size: 1rem;
  margin-left: 0;
  border-radius: 2rem;
}

.form-field .error-message {
  color: red;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

button[type="submit"] {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: var(--brown);
  background-color: var(--yellow);
  border: none;
  border-radius: 4px;
  cursor: pointer;

  text-align: center;

  display: inline-block;
  width: fit-content;

  /* border-radius: 50px; */

  font-weight: 500;

  transition: all 400ms ease;
}

button[type="submit"]:disabled {
  background-color: #ccc;
}
