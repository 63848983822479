.policy {
  padding-top: 8rem;
}
.policy h1 {
  text-transform: uppercase;
  color: var(--dtn-color-1);
}

.policy h4 {
  text-transform: capitalize;
  padding: 1.5rem 0 0 0;
}

.policy ul {
  padding-top: 0.8rem;
  padding-left: 4%;
}
.policy li {
  list-style-type: decimal;
  font-size: 1.1rem;
}
