.serviceSection {
  padding: 2rem 0;
}
.service-cards {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.service-card {
  flex-basis: 100%;
  padding: 15px;

  box-sizing: border-box;
}
.service-card-content-wrapper {
  border: solid 1px var(--grey-deep);
  border-radius: 3rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.card-image {
  position: relative;
  border: solid 1px var(--grey-deep);
  border-radius: 3rem;
  overflow: hidden;
}

.card-img-item {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card-image:hover .card-img-item {
  transform: scale(1.1);
}

.card-content {
  padding: 20px;
  border-radius: 2rem;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.card-title {
  color: var(--mauve);
  text-transform: capitalize;
  font-size: x-large;
  margin-bottom: 10px;
}

.card-body {
  color: #333;
}

.partnerWithUsSection {
  padding: 2rem 0;
  position: relative;
}

@media screen and (min-width: 768px) {
  .service-card {
    flex-basis: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .service-card {
    flex-basis: 33.333%;
  }
}
